import React from 'react';

import GlobalStyle, { variables } from '../styles/GlobalStyles'
import styled from 'styled-components';

import SEO from '../components/seo'
import Nav from '../components/nav'
import Footer from '../components/footer';
import Wrapper from '../styles/Wrapper';
import { Section, SectionHeading } from '../styles/Section';
import { Grid } from '../styles/case-study/Grid';
import { BoxCard } from '../styles/case-study/Card';
import { TagList, TagItem } from '../styles/Tags';
import Testimonials from '../components/aboutComponents/testimonials';
import WhereIWorked from '../components/aboutComponents/whereiworked';
import Reading from '../components/aboutComponents/reading';

const IndexPage = () => (
  <>
    <GlobalStyle />
    {/* eslint-disable-next-line */}
    <SEO title="Jonathan Hoy - Product Designer" />
    <header>
      <Nav />
    </header>
    <main>
        <Section className='about-page about-page-profile'>
          <Wrapper>
            <AboutGrid>
              <SectionHeading className='section-heading'>Hi, I'm Jonathan. 👋🏻</SectionHeading>
              <AboutGridItem columns="3 / 11">
                <p>I’m passionate about applying a user-centered approach to creating digital experiences that are intuitive, accessible, and a delight to use to achieve business goals. With a background in web development I can appreciate the technical feasibility of implementing designs and will bring that expertise to a design team.</p>
                <p>Working directly with designers on cross-functional run-teams in the past I have found myself more interested in answering the question of <span className='italic'>why</span> rather than the question of <span className='italic'>how</span>.</p>
              </AboutGridItem>
            </AboutGrid>
          </Wrapper>
        </Section>
        <Section className='about-page about-page-testimonials'>
          <Wrapper>
            <AboutGrid>
              <SectionHeading className='section-heading'>Testimonials</SectionHeading>
              <AboutGridItem columns="3 / 11">
                <Testimonials />
              </AboutGridItem>
            </AboutGrid>
          </Wrapper>
        </Section>
        <Section className='about-page about-page-whereiworked'>
          <Wrapper>
            <AboutGrid>
              <SectionHeading className='section-heading'>Where I've Worked</SectionHeading>
              <AboutGridItem columns="3 / 11">
                <WhereIWorked />
              </AboutGridItem>
            </AboutGrid>
          </Wrapper>
        </Section>
        <Section className='about-page about-page-skills'>
          <Wrapper>
            <AboutGrid>
              <SectionHeading className='section-heading'>Skills</SectionHeading>
              <AboutGridItem columns="3 / 11">
                <Grid columns="2" mdColumns="2" mt="0" mb="0">
                  <BoxCard>
                    <p className='heading'>Design</p>
                    <TagList itembackgroundcolor={`${variables.white}`}>
                      <TagItem>UX Design</TagItem>
                      <TagItem>UI Design</TagItem>
                      <TagItem>UX Research</TagItem>
                      <TagItem>Prototyping</TagItem>
                      <TagItem>User Testing</TagItem>
                      <TagItem>Responsive Design</TagItem>
                    </TagList>
                  </BoxCard>
                  <BoxCard>
                    <p className='heading'>Software</p>
                    <TagList itembackgroundcolor={`${variables.white}`}>
                      <TagItem>Figma</TagItem>
                      <TagItem>FigJam</TagItem>
                      <TagItem>Adobe XD</TagItem>
                      <TagItem>InVision</TagItem>
                      <TagItem>Miro</TagItem>
                      <TagItem>Jira</TagItem>
                      <TagItem>Confluence</TagItem>
                      <TagItem>Trello</TagItem>
                    </TagList>
                  </BoxCard>
                  <BoxCard>
                    <p className='heading'>Development</p>
                    <TagList itembackgroundcolor={`${variables.white}`}>
                      <TagItem>HTML</TagItem>
                      <TagItem>CSS</TagItem>
                      <TagItem>JavaScript</TagItem>
                      <TagItem>React</TagItem>
                      <TagItem>Web Accessibility</TagItem>
                      <TagItem>RESTful APIs</TagItem>
                    </TagList>
                  </BoxCard>
                </Grid>
              </AboutGridItem>
            </AboutGrid>
          </Wrapper>
        </Section>
        <Section className='about-page about-page-reading'>
          <Wrapper>
            <AboutGrid>
              <SectionHeading className='section-heading'>Reading</SectionHeading>
              <AboutGridItem columns="3 / 11">
                <Reading />
              </AboutGridItem>
            </AboutGrid>
          </Wrapper>
        </Section>
    </main>
    <footer>
      <Footer />
    </footer>
  </>
)

const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2rem;
  .section-heading {
    grid-column: 1 / 13;
    text-align: center;
  }
  p {
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .hobby-item {
    margin-bottom: 5rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    h3 {
      font-weight: 600;
      font-size: 1.25rem;
    }
    p {
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    ul {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 2rem;
      margin-top: 2rem;
      margin-bottom: 0;
      @media (max-width: ${variables.sm}) {
        grid-template-columns: 1fr;
        grid-row-gap: 1.5rem;
      }
    }
    .caption {
      font-weight: 400;
      margin-top: 0.25rem;
      margin-bottom: 0;
      font-size: 0.875rem;
    }
    img {
      border-radius: ${variables.brstandard};
    }
  }
`;

const AboutGridItem = styled.div`
  grid-column: ${props => props.columns && `${props.columns}`};
  @media (max-width: ${variables.md}) {
    grid-column: 1 / 13;
  }
`;

export default IndexPage