import React from "react";
import styled from "styled-components";
import { variables } from '../../../styles/GlobalStyles';

const Testimonials = () => (
  <TestimonialList>
    <TestimonialItem>
      <div className="testimonial">
        <p className='description'><span className='quote-mark opening'>“</span>Jonathan is a detail oriented designer with a strong sense of visual design. His engineering background allows him to approach UX design with unique perspective that foresees and optimizes for many development concerns.</p>
        <p className='description'>As a member of the UX education team, Jonathan's ability to support UX students with varying needs was apparent. He was always patient, approachable, and willing to go above and beyond to ensure that each student had the guidance and assistance they needed to succeed. He was also a friendly and collaborative member of our team, always willing to lend a hand and offer insights and ideas to improve the program.</p>
        <p className='description'>He's a quick learner & would be a great asset to any design team.<span className='quote-mark closing'>”</span></p>
      </div>
      <div className='details'>
        <p className='heading'>Ann-Marie Sebastian</p>
        <div className="secondary-details">
          <p className='role'>Team Lead, UX Design • BrainStation</p>
          <a
            href="https://www.linkedin.com/in/ann-mariesebastian/"
            aria-label='Go to LinkedIn profile, opens in a new window'
            target='_blank'
            rel="noreferrer"
            className='link inline'
          >
            LinkedIn <span className='arrow'>→</span>
          </a>
        </div>
      </div>
    </TestimonialItem>
    <TestimonialItem>
      <div className="testimonial">
        <p className='description'><span className='quote-mark opening'>“</span>Jonathan has been an amazing individual to work with. He’s detail oriented, accountable and always delivers! Jonathan’s analytical and problem solving skills makes him a great designer. Jonathan understands the importance of UX process from research to execution. His development background is a great addition to his skill set. Jonathan’s a well rounded designer and team player that will be a great addition to any team.<span className='quote-mark closing'>”</span></p>
      </div>
      <div className='details'>
        <p className='heading'>Kelly Zhang</p>
        <div className="secondary-details">
          <p className='role'>Educator, UX Design • BrainStation</p>
          <a
            href="https://www.linkedin.com/in/kunkellyzhang/"
            aria-label='Go to LinkedIn profile, opens in a new window'
            target='_blank'
            rel="noreferrer"
            className='link inline'
          >
            LinkedIn <span className='arrow'>→</span>
          </a>
        </div>
      </div>
      
    </TestimonialItem>
  </TestimonialList>
)

export default Testimonials;

const TestimonialList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  margin: 0;
  @media (max-width: ${variables.md}) {
    display: flex;
    flex-direction: column;
  }
`;

const TestimonialItem = styled.li`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  .testimonial {
    padding: 1.5rem;
    background: ${variables.grey100};
    border-radius: ${variables.brstandard};
  }
  .description {
    position: relative;
    margin-top: 1rem;
    &:first-of-type {
      margin-top: 0;
    }
    .opening {
      text-indent: -0.3em;
      position: absolute;
    }
  }
  .details {
  }
  .secondary-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${variables.sm}) {
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
    }
  }
  .link {
    span {
      transition: transform 0.2s;
      display: inline-block;
    }
    &:hover {
      span {
        transform: rotate(-45deg);
      }
    }
    @media (max-width: ${variables.sm}) {
      white-space: nowrap;
    }
  }
  .heading {
    font-weight: 600;
    font-size: 1.25rem;
    margin: 0;
  }
  .role {
    margin-top: 0;
    margin-bottom: 2rem;
    font-weight: 500;
    color: ${variables.grey900};
  }
`;