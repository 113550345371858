import React from 'react';
import styled from 'styled-components';
import { Grid, GridChild } from '../../../styles/case-study/Grid';
import { variables } from '../../../styles/GlobalStyles';

const WhereIWorked = () => (
  <Styles>
    <p>As a developer I've worked on cross-functional teams in both larger corporate, agile environments and smaller SaaS evironments.</p>
    <Grid columns="3" mdColumns="3" smGap="2rem" align="center" mt="0" mb="0">
      <GridChild justify="center">
        <img src="/bmo.svg" alt="logo of BMO Financial Group"></img>
      </GridChild>
      <GridChild justify="center">
        <img src="/uberflip.svg" alt="logo of Uberflip"></img>
      </GridChild>
      <GridChild justify="center">
        <img src="/thebay.svg" alt="logo of The Bay"></img>
      </GridChild>
    </Grid>
  </Styles>
);

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  align-items: center;
  p {
    width: 65%;
    text-align: center;
    @media (max-width: ${variables.sm}) {
      width: 100%;
      text-align: left;
    }
  }
`;

export default WhereIWorked;