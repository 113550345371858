import React from 'react';
import styled from 'styled-components';
import { GridList } from '../../../styles/case-study/Grid';
import { variables } from '../../../styles/GlobalStyles';

const books = 
[
  {
    src: "/books/designofeverydaythings.jpg",
    title: "The Design of Everyday Things",
    author: "Don Norman",
  },
  {
    src: "/books/sprint.jpg",
    title: "Sprint",
    author: "Jake Knapp",
  },
  {
    src: "/books/hooked.jpg",
    title: "Hooked",
    author: "Nir Eyal",
  },
  {
    src: "/books/emotionaldesign.jpg",
    title: "Emotional Design",
    author: "Don Norman",
  },
  {
    src: "/books/articulatingdesigndecisions.jpg",
    title: "Articulating Design Decisions",
    author: "Tom Greever",
  },
  {
    src: "/books/mismatch.jpg",
    title: "Mismatch",
    author: "Kat Holmes",
  },
]

const Reading = () => (
  <Styles>
    <p className='description'>These are some of the books about design that I've read or am reading.</p>
    <GridList columns="4" mdColumns="4" smColumns="2">
      {books.map((item) => {
        return (
          <li>
            <img className="image" src={item.src} alt={item.title}/>
            <div className='book-details'>
              <p className='book-title'>{item.title}</p>
              <p className='book-author'>{item.author}</p>
            </div>
          </li>
        )
      })}
    </GridList>
  </Styles>
);

const Styles = styled.div`
  li {
    display: flex;
    flex-direction: column;
    align-items: normal;
    gap: 0.5rem;
  }
  p:not(.book-title):not(.book-author) {
    text-align: center;
    @media (max-width: ${variables.sm}) {
      text-align: left;
    }
  }
  .book-details {
  }
  .book-title {
    font-weight: 500;
    margin-bottom: 0;
  }
  .book-author {
    font-weight: 400;
  }
`;

export default Reading;